import React from 'react'

import Avatar from '../components/Avatar'
import Layout from '../components/Layout/'
import SEO from '../components/Seo'
import Profile from '../components/Profile'
import SocialLinks from '../components/SocialLinks'

import { MainContent } from '../styles/base'

const IndexPage = () => (
  <Layout>
    <SEO
      title={"Home"}
      description="Full-stack Web Developer"
    />
    <MainContent>
      {/*<Profile />
      <SocialLinks />*/}
      <Avatar />
      <p style={{textAlign: 'center'}}><span style={{fontSize: '28px'}}>I'm <strong>Augusto Bennemann</strong>, a full-stack web developer.</span></p>
      <div style={{width: '200px', marginTop: '40px', float:'right'}}><SocialLinks /></div>
    </MainContent>
  </Layout>
)

export default IndexPage
