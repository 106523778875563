import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'

export const AvatarWrapper = styled(Img)`
  border-radius: 50%;
  height: 12rem;
  margin: 1.4rem auto 1.4rem;
  width: 12rem;

  ${media.lessThan('large')`
    height: 10rem;
    width: 10rem;
  `}
`
